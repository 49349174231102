import { Route } from '@angular/router';

import { provideOivViewer } from '@mobi/oiv-viewer-ng-jslib';
import {
    languageResolver,
    PAGE_URL_PATH,
    PROTEKTA,
    provideOivCoreSharedStore,
    URL_PATH,
} from '@mobi/oiv-viewer-utils-ng-jslib';

export const appRoutes: Route[] = [
    {
        path: URL_PATH.AVB,
        redirectTo: '/de/' + PAGE_URL_PATH.NOT_FOUND,
        pathMatch: 'full',
    },
    {
        path: ':language',
        resolve: { language: languageResolver },
        data: { app: PROTEKTA },
        providers: [provideOivCoreSharedStore],
        children: [
            {
                path: URL_PATH.AVB + '/:urlName',
                providers: [provideOivViewer],
                loadChildren: () => import('@mobi/oiv-viewer-ng-jslib').then(m => m.MainLayoutModule),
            },
            {
                path: PAGE_URL_PATH.NOT_FOUND,
                loadComponent: () => import('@mobi/oiv-viewer-ng-jslib').then(m => m.NotFoundPageComponent),
            },
            {
                path: PAGE_URL_PATH.NOT_AVAILABLE,
                loadComponent: () => import('@mobi/oiv-viewer-ng-jslib').then(m => m.NotAvailableComponent),
            },
        ],
    },
    {
        path: '',
        redirectTo: '/de/' + PAGE_URL_PATH.NOT_FOUND,
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: '/de/' + PAGE_URL_PATH.NOT_FOUND,
        pathMatch: 'full',
    },
];
