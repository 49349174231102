<mobi-ui-header-major [headerLogo]="HEADER_LOGO.PROTEKTA" (logoClick)="navigateToHomePage()">
    @if (isDisplayIcon()) {
        <div [ngClass]="[iconMenu.className ? iconMenu.className.join(' ') : '']">
            <a class="mobi-ui-link-block-major" (click)="toggleIcons(iconMenu)">
                @if (!iconMenu.isDisabled) {
                    <mobi-ui-icon-major
                        [ngClass]="{ 'mobi-ui-ink-grey': iconMenu.isDisabled }"
                        [name]="iconMenu.icon.name"
                        [isInked]="true"
                    ></mobi-ui-icon-major>
                }
            </a>
        </div>
        <div [ngClass]="[iconTheX.className ? iconTheX.className.join(' ') : '']">
            <a class="mobi-ui-link-block-major" (click)="toggleIcons(iconTheX)">
                @if (!iconTheX.isDisabled) {
                    <mobi-ui-icon-major
                        [ngClass]="{ 'mobi-ui-ink-grey': iconTheX.isDisabled }"
                        [name]="iconTheX.icon.name"
                        [isInked]="true"
                    ></mobi-ui-icon-major>
                }
            </a>
        </div>
    }
</mobi-ui-header-major>
